import Vue from "vue";
import axios from "axios";
import router from "@/router/router";
// import HOST from "@/utils/https/Host";
const currentENV = process.env.NODE_ENV
const HOST = currentENV === 'production' ? window.location.origin + '/' : '/'
import { getSession, Ddebounce, removeSession } from "@/utils/utils.js";

// loading对象
let loading = null;

// 当前正在请求的数量
let needLoadingRequestCount = 0;

// 显示loading
function showLoading() {
  if (needLoadingRequestCount === 0 && !loading) {
    loading = Vue.prototype.$loading({
      lock: true,
      text: "请求中,请稍等...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)"
    });
  }
  needLoadingRequestCount++;
}

// 隐藏loading
function hideLoading() {
  needLoadingRequestCount--;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0);
  if (needLoadingRequestCount === 0) {
    toHideLoading();
  }
}

let toHideLoading = Ddebounce(() => {
  loading && loading.close();
  loading = null;
}, 500, false);

const service = axios.create({
  baseURL: HOST,
  timeout: 30000, // 请求超时时间
  headers: {},
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // config.headers = Object.assign(config.headers, { Authorization: getSession('yxtoken') || "" })
    config.headers = Object.assign(config.headers, { "ADMIN-TOKEN": getSession('yxtoken') || "" })
    // 判断当前请求是否设置了不显示 Loading
    if (config.headers.showLoading === true) {
      showLoading(config.headers.loadingTarget);
    }
    // 返回图片流方式处理
    if (config.url.includes("/login/captcha")) {
      config.responseType = "arraybuffer"
    }
    // 返回表格blob流
    if (config.url.includes("/export")) {
      config.responseType = "blob"
    }
    if (config.url.includes("Download")) {
      config.responseType = "blob"
      console.log(1213);
    }
    if (config.method.toLocaleLowerCase() === "get" || config.method.toLocaleLowerCase() === "delete") {
      config.params = config.data;
    }
    return config;
  },
  error => {
    // 判断当前请求是否设置了不显示 Loading
    if (error.config.headers.showLoading !== false) {
      hideLoading();
    }
    return Promise.reject(error);
  }
);

// 返回拦截器
service.interceptors.response.use(
  config => {
    // 判断当前请求是否设置了不显示 Loading
    if (config.headers.showLoading !== false) {
      hideLoading();
    }
    // 签名过期情况处理
    if (config.data.code === 10100) {
      removeSession("yxtoken");
      router.push("/login");
    }
    return config;
  },
  error => {
    // 判断当前请求是否设置了不显示 Loading
    if (error.config.headers.showLoading !== false) {
      hideLoading();
    }
    return Promise.reject(error);
  }
);

// 挂载请求
Vue.prototype.$axios = service;

// 请求统一处理
function requst(options) {
  return new Promise((resolve, reject) => {
    options
      .then(res => {
        // 返回图片流方式处理
        if (res.config.url.includes("/login/captcha")) {
          resolve('data:image/png;base64,' + btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')))
        } else if(res.config.url.includes("/export")){
          resolve(res);
        }else if(res.config.url.includes("Download")){
          resolve(res);
        }else {
          if (res.data?.code == 0) {
            resolve(res.data);
          } else if(res.data?.code == 10200){
            resolve(res.data);
          } else {
            reject(res.data.msg || res.data.Msg || "加载数据超时，请稍后重试1");
          }
        }
      })
      .catch(error => {
        if (error.message.includes(`timeout of ${error.config.timeout}ms exceeded`)) {
          reject("加载数据超时，请稍后重试");
        } else {
          reject(error);
        }
      });
  });
}

// get.post.put.del.patch请求方法提取
const get = (url, params, showLoading = true) => {
  return requst(service.get(url, { data: params }, { headers: { showLoading:showLoading } }));
};
const post = (url, params, showLoading = true) => {
  return requst(service.post(url, params, { headers: { showLoading } }));
};
const put = (url, params, showLoading = true) => {
  return requst(service.put(url, params, { headers: { showLoading } }));
};
const del = (url, params, showLoading = true) => {
  return requst(
    service.delete(url, { data: params }, { headers: { showLoading } })
  );
};
const patch = (url, params, showLoading = true) => {
  return requst(service.patch(url, { data: params }, { headers: { showLoading } }));
};

export default requst;
export { get, post, put, del, patch };
